import {
  ADD_WHITE_LIST_COMPANIES_FAIL,
  ADD_WHITE_LIST_COMPANIES_REQUEST,
  ADD_WHITE_LIST_COMPANIES_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_SUB_CUSTOMER_FAIL,
  CREATE_SUB_CUSTOMER_REQUEST,
  CREATE_SUB_CUSTOMER_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_RESET,
  DELETE_ACCOUNT_SUCCESS,
  GENERATE_NEW_PASSWORD_FAIL,
  GENERATE_NEW_PASSWORD_REQUEST,
  GENERATE_NEW_PASSWORD_SUCCESS,
  GET_CUSTOMER_INFO_FAIL,
  GET_CUSTOMER_INFO_REQUEST,
  GET_CUSTOMER_INFO_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_SUB_CUSTOMERS_FAIL,
  GET_SUB_CUSTOMERS_REQUEST,
  GET_SUB_CUSTOMERS_SUCCESS,
  GET_WHITE_LIST_COMPANIES_FAIL,
  GET_WHITE_LIST_COMPANIES_REQUEST,
  GET_WHITE_LIST_COMPANIES_SUCCESS,
  REMOVE_SUB_CUSTOMER_FAIL,
  REMOVE_SUB_CUSTOMER_REQUEST,
  REMOVE_SUB_CUSTOMER_SUCCESS,
  REMOVE_WHITE_LIST_COMPANY_FAIL,
  REMOVE_WHITE_LIST_COMPANY_REQUEST,
  REMOVE_WHITE_LIST_COMPANY_SUCCESS,
  UPDATE_API_LIMIT_FAIL,
  UPDATE_API_LIMIT_REQUEST,
  UPDATE_API_LIMIT_SUCCESS,
  UPDATE_CUSTOMER_INFO_FAIL,
  UPDATE_CUSTOMER_INFO_REQUEST,
  UPDATE_CUSTOMER_INFO_SUCCESS,
} from "../constants/customerContants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  customers: [],
  subCustomers: [],
  customerInfo: {},
  whitelistCompanies: [],
};

export const createCustomerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === CREATE_CUSTOMER_REQUEST) {
    return { ...state, loading: true };
  } else if (type === CREATE_CUSTOMER_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === CREATE_CUSTOMER_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const getCustomersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_CUSTOMERS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_CUSTOMERS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      customers: payload,
      errors: [],
    };
  } else if (type === GET_CUSTOMERS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const createSubCustomerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === CREATE_SUB_CUSTOMER_REQUEST) {
    return { ...state, loading: true };
  } else if (type === CREATE_SUB_CUSTOMER_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === CREATE_SUB_CUSTOMER_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const getSubCustomersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_SUB_CUSTOMERS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_SUB_CUSTOMERS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      subCustomers: payload,
      errors: [],
    };
  } else if (type === GET_SUB_CUSTOMERS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const removeSubCustomersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === REMOVE_SUB_CUSTOMER_REQUEST) {
    return { ...state, loading: true };
  } else if (type === REMOVE_SUB_CUSTOMER_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === REMOVE_SUB_CUSTOMER_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const getCustomerInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_CUSTOMER_INFO_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_CUSTOMER_INFO_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      customerInfo: payload,
      errors: [],
    };
  } else if (type === GET_CUSTOMER_INFO_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const updateCustomerInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === UPDATE_CUSTOMER_INFO_REQUEST) {
    return { ...state, loading: true };
  } else if (type === UPDATE_CUSTOMER_INFO_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === UPDATE_CUSTOMER_INFO_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const addWhiteListCompaniesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === ADD_WHITE_LIST_COMPANIES_REQUEST) {
    return { ...state, loading: true };
  } else if (type === ADD_WHITE_LIST_COMPANIES_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === ADD_WHITE_LIST_COMPANIES_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const getWhiteListCompaniesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_WHITE_LIST_COMPANIES_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_WHITE_LIST_COMPANIES_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      whitelistCompanies: payload,
      errors: [],
    };
  } else if (type === GET_WHITE_LIST_COMPANIES_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const removeWhiteListCompaniesReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  if (type === REMOVE_WHITE_LIST_COMPANY_REQUEST) {
    return { ...state, loading: true };
  } else if (type === REMOVE_WHITE_LIST_COMPANY_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === REMOVE_WHITE_LIST_COMPANY_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const updateAPILimitReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === UPDATE_API_LIMIT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === UPDATE_API_LIMIT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === UPDATE_API_LIMIT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const generateNewPasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GENERATE_NEW_PASSWORD_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GENERATE_NEW_PASSWORD_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === GENERATE_NEW_PASSWORD_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const deleteAccountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === DELETE_ACCOUNT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === DELETE_ACCOUNT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === DELETE_ACCOUNT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else if (type === DELETE_ACCOUNT_RESET) {
    return {
      ...state,
      loading: false,
      success: false,
      errors: [],
    };
  } else {
    return state;
  }
};
