

import React, { useState, useEffect } from "react";
import { Button, Table, Spin, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

// Components
import BasePageContainer from "../layout/PageContainer";
import { webRoutes } from "../../routes/web";
import {
  fetchCompaniesWithoutPDF,
  scrapPDFs,
} from "../../redux/methods/companiesMethods";
import { checkJobStatus } from "../../redux/methods/documentMethods";
import axios from "axios";
import { BASE_URL } from "../../redux/constants/constants";

const breadcrumb = {
  items: [
    {
      key: webRoutes.dashboard,
      title: <a href={webRoutes.adminoverview}>Admin Overview</a>,
    },
    {
      key: webRoutes.report,
      title: <a href={webRoutes.reportsandfeedback}>Reports & Feedback</a>,
    },
  ],
};

export default function Reports() {
  const [loadingButtons, setLoadingButtons] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [processingCompany, setProcessingCompany] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companiesWithoutPDFs, total, loading } = useSelector(
    (state) => state.fetchCompaniesWithoutPDFReducer
  );
  const { jobStatus, errors } = useSelector(
    (state) => state.uploadDocumentReducer
  );

  useEffect(() => {
    if (errors.length > 0) {
      errors.forEach((err) => {
        toast.error(err.response?.data?.msg || err.message);
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(fetchCompaniesWithoutPDF(currentPage, pageSize, searchQuery));
  }, [dispatch, currentPage, pageSize, searchQuery]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkJobStatus());
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (jobStatus?.company_name) {
      setLoadingButtons((prev) => ({
        ...prev,
        [jobStatus.id]: {
          loading: jobStatus.status !== "ONGOING",
        },
      }));
    }
    if (jobStatus.status === "ONGOING") {
      setIsModalVisible(false);
      navigate(webRoutes.addcompnay);
    }
  }, [jobStatus, navigate]);

  const scrapData = (_, record) => {
    setProcessingCompany(record);
    setIsModalVisible(true);
    setLoadingButtons((prev) => ({
      ...prev,
      [record.id]: { loading: true },
    }));

    // Disable all other buttons
    companiesWithoutPDFs.forEach((company) => {
      if (company.id !== record.id) {
        setLoadingButtons((prev) => ({
          ...prev,
          [company.id]: { disabled: true },
        }));
      }
    });

    dispatch(
      scrapPDFs(record.company_name, record.year_of_emissions, record.id)
    );

    // Close modal and navigate after 3 seconds
    // setTimeout(() => {
    //   setIsModalVisible(false);
    //   navigate(webRoutes.addcompnay);
    // }, 3000);
  };
  useEffect(() => {
    const MonitorPdfGatherResponse = async () => {
      const response = await axios.get(
        `${BASE_URL}/api/document/get-status-for-pdf-gather`
      );
      if (response && response.data.status === "PROCESSING") {
        console.log(response.data.status);
        setIsModalVisible(true);
      } else {
        console.log("No Pdf Gather Process is continue right now");
      }
    };

    // Set up an interval to check status every 5 seconds
    const intervalId = setInterval(MonitorPdfGatherResponse, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const columns = [
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>YEAR OF EMISSION</span>,
      dataIndex: "year_of_emissions",
      key: "year_of_emissions",
    }
    ,

    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>FETCH PDF</span>,
      dataIndex: "fetch_pdf",
      key: "fetch_pdf",
      render: (_, record) => {
        const isLoading = loadingButtons[record.id]?.loading;
        const isDisabled =
          loadingButtons[record.id]?.disabled || jobStatus.status === "ONGOING";
        return (
          <Button
            onClick={() => scrapData(_, record)}
            disabled={isDisabled}
            className={
              isDisabled
                ? "cursor-not-allowed bg-white"
                : "text-black bg-white pb-4 w-16 rounded-md"
            }
          >
            {isLoading ? <Spin /> : "Fetch"}
          </Button>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      
    },
  ];

  // Handle pagination change

  return (
    <>
<style>
  {`
    .companies-table-container {
      overflow-x: auto; /* Enables horizontal scrolling */
      width: 100%;
    }

    .companies-table .ant-table-thead th {
      background-color: white !important;
      // border: 2px solid black;
      color: gray !important;
      font-size: 12px !important;
      font-weight: normal !important;
    }

    .companies-table .ant-table-tbody tr:nth-child(even) {
      background-color: white !important;
    }

    .companies-table .ant-table-tbody tr:nth-child(odd) {
      background-color: #F9FAFB !important;
    }

    .companies-table .ant-table-row:hover > td {
      background-color: #FF6B00 !important;
      transition: background-color 0.3s ease;
    }

    .companies-table .ant-table-row:hover .ant-btn {
      background-color: #171733 !important;
      color: white !important;
      border-color: #171733 !important;
      transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    }

    .companies-table .ant-table-row:hover .ant-table-cell,
    .companies-table .ant-table-row:hover .ant-table-cell span {
      color: white !important;
      transition: color 0.3s ease;
    }

    .companies-table .ant-spin-dot i {
      background-color: currentColor !important;
    }
  `}
</style>
      <BasePageContainer breadcrumb={breadcrumb}>
        {/* Search Input */}
        <div className="mx-4 mt-4" style={{ marginBottom: 16 }}>
          <Input
            placeholder="Search Company Name"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            style={{ width: 300 }}
            className=" focus:border-orange-500 focus:outline-none focus:ring-0" // Add Tailwind classes for border styling
          />
        </div>

        {/* Company Table */}
        <Table
          columns={columns}
          dataSource={companiesWithoutPDFs}
          loading={loading}
          pagination={false}
          className="companies-table"
          // onRow={() => ({
          //   className: "group hover:bg-[#FF6B00] transition-colors",
          // })}
        />
        <Modal visible={isModalVisible} closable={false} footer={null} centered>
          <div className="text-center p-8">
            <Spin size="large" />
            <h3 className="mt-6 text-xl font-semibold">Finding ESG Report</h3>
            <p className="mt-4 text-gray-600">
              Our scraping tools are currently trying to find the most relevant
              and accurate ESG report for {processingCompany?.company_name} the
              year {processingCompany?.year}. This process may take up to 10
              minutes as we will find multiple documents and use our AI to
              select the most accurate one.
            </p>
          </div>
        </Modal>

        {/*This will be the div which will change the data from using button previous and next  */}
        <div className="flex flex-col items-center space-y-4 my-4">
          <div className="flex items-center space-x-4">
            <Button.Group>
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`px-4 py-2 w-20 rounded-md  disabled:cursor-not-allowed  ${
                  currentPage === 1
                    ? "bg-gray-400 cursor-not-allowed text-black"
                    : "text-white bg-[#171733] "
                }`}
              >
                Previous
              </button>
            </Button.Group>
            <p className="text-sm ">
              {`Page ${currentPage} of ${Math.ceil(total / 10)}`}
            </p>
            <Button.Group>
              <button
                disabled={
                  currentPage === total || companiesWithoutPDFs.length < 10
                }
                onClick={() => setCurrentPage(currentPage + 1)}
                className={`px-4 py-2 w-20 rounded-md  disabled:cursor-not-allowed ${
                  currentPage === total || companiesWithoutPDFs.length < 10
                    ? "bg-gray-200 cursor-not-allowed"
                    : "text-white bg-[#171733] "
                }`}
              >
                Next
              </button>
            </Button.Group>
          </div>
        </div>
      </BasePageContainer>
    </>
  );
}
