



import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import Prism from "prismjs";


//Components
// import UploadDoc from "./UploadDoc";
// import StoreDoc from "./StoreDoc";
import BasePageContainer from "../layout/PageContainer";
import { webRoutes } from "../../routes/web";
import "prismjs/themes/prism-okaidia.css";
import CtEyeExtraction from "./CteyeExtraction";

const { Step } = Steps;
const breadcrumb = {
  items: [
    {
      key: webRoutes.dashboard,
      title: <Link to={webRoutes.addcompnay}>Dashboard</Link>,
    },
    {
      key: webRoutes.uploadDocument,
      title: (
        <Link to={webRoutes.uploadDocument}>Upload Document Llama</Link>
      ),
    },
  ],
};

export default function CtEye() {


  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <BasePageContainer >
    
       <CtEyeExtraction/>
      
     
    </BasePageContainer>
  );
}
