import {
      FETCH_COMPANIES_WITHOUT_PDF_REQUEST,
      FETCH_COMPANIES_WITHOUT_PDF_SUCCESS,
      FETCH_COMPANIES_WITHOUT_PDF_FAIL,
      SCRAP_PDF_REQUEST,
      SCRAP_PDF_SUCCESS,
      SCRAP_PDF_FAIL,
    } from "../constants/companiesConstants";
    import { BASE_URL } from "../constants/constants";
    import axios from "axios";
    
    // Action to fetch companies without PDFs






    
    export const fetchCompaniesWithoutPDF = (page, limit = 10, company_name = '') => {
      console.log(page)
      return async (dispatch) => {
        try {
          dispatch({ type: FETCH_COMPANIES_WITHOUT_PDF_REQUEST });
          
          const response = await axios.get(`${BASE_URL}/api/document/companies-without-pdf`, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              page:page,
              limit:limit,
              company_name:company_name,  // Add company_name if filtering by it
            }
          });
          // console.log(response)
          console.log(response.data.data.data)

          
          dispatch({
            type: FETCH_COMPANIES_WITHOUT_PDF_SUCCESS,
            payload: {
              data: response.data.data.data,  // Company data
              total: response.data.data.total,
             // Total number of companies
            },
          });
        } catch (error) {
          console.error(error);
          dispatch({
            type: FETCH_COMPANIES_WITHOUT_PDF_FAIL,
            payload: [{ message: error.message }],
          });
        }
      };
    };
    
    
    // Action to scrape PDFs



    export const scrapPDFs = (companyName, year,id,selectedCountries,lei) => {
      if(!selectedCountries){
        selectedCountries=""
      }
      
      if(!lei){
        lei=""
      }
      console.log("Year and Name in Frontend .......................... in // Action to scrape PDFs "+companyName,year,selectedCountries)
      return async (dispatch) => {
        try {
          dispatch({ type: SCRAP_PDF_REQUEST });
    
          const response = await axios.post(
            `${BASE_URL}/api/document/scrap-pdfs`,
            {
              company_name:companyName,
              year_of_emissions:year,
              id:id,
              selectedCountries:selectedCountries,
              lei:lei
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
    
          dispatch({
            type: SCRAP_PDF_SUCCESS,
            payload: response.data,
          });
        } catch (error) {
          console.error(error);
          dispatch({
            type: SCRAP_PDF_FAIL,
            payload: [{ message: error.message }],
          });
        }
      };
    };
    