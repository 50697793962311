

import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form, Select, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../redux/constants/constants";
import { getStructuredDocuments } from "../../redux/methods/documentMethods";
import { toast } from "sonner";

// Components
import BasePageContainer from "../layout/PageContainer";
import { webRoutes } from "../../routes/web";
import { Ellipsis } from "lucide-react";

const { Title } = Typography;
const { Option } = Select;

const breadcrumb = {
  items: [
    {
      key: webRoutes.dashboard,
      title: <a href={webRoutes.adminoverview}>Admin Overview</a>,
    },
    {
      key: webRoutes.reports,
      title: <a href={webRoutes.reportsandfeedback}>Reports & Feedback</a>,
    },
  ],
};

// Fetching data from API

export default function Reports() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { documents, loading } = useSelector(
    (state) => state.getStructuredDocumentsReducer
  );

  // States
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentNote, setCurrentNote] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(null);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(0);
  const [isReportingPage, setIsReportingPage] = useState(true);
  const [pagelimit,setPageLimit] = useState()

  useEffect(() => {
   
    dispatch(
      getStructuredDocuments(
        "",
        "",
        "",
        pagelimit,
        currentPage,
        selectedReason,
        selectedApprovalStatus,
        isReportingPage
      )
    );
    
  }, [dispatch, currentPage, selectedReason, selectedApprovalStatus]);
  
  useEffect(() => {
    const fetchData = async () => {
   
      try {
     
        const response = await axios.post(
          `${BASE_URL}/api/document/get-structured-data`,
          {
            company_name: "",
            country_code: "",
            revenuetsek: "",
            pagelimit,
            page: currentPage,
            reason_for_reporting: selectedReason,
            admin_approval: selectedApprovalStatus,
            isReportingPage,
          }
        );

        const { data, pagination } = response.data;
         setFilteredDocuments(data)

        // Update total pages based on response data
        if (
          (selectedApprovalStatus && selectedApprovalStatus !== "all") ||
          (selectedReason && selectedReason !== "all")
        ) {
          setTotalPages(Math.ceil(pagination.totalCount / 10));
        } else {
          setTotalPages(Math.ceil(pagination.totalCount/10));
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchData();
  }, [ selectedReason, selectedApprovalStatus]);
 
  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1); 
      dispatch(
        getStructuredDocuments("", "", "",pagelimit, currentPage + 1,selectedReason,selectedApprovalStatus, isReportingPage)
      ); 
    } else {
      toast.info("You're already at the last page.");
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1); 
      dispatch(
        getStructuredDocuments("", "", "", currentPage - 1,selectedReason,selectedApprovalStatus, isReportingPage)
      ); 
    } else {
      toast.info("You're already at the first page.");
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/document/get-added-reasons-for-reporting-dropdown`
        );
        if (response.data && response.data.status) {
          setOptions(response.data.data);
        } else {
          toast.error("Failed to load options");
        }
      } catch (error) {
        toast.error("Failed to load options");
      }
    };
    fetchOptions();
  }, []);

  const handleSeeChanges = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
    checkDataChanges(record);
  };

  const handleSeeNotes = (record) => {
    setCurrentNote(record.reporting_comments);
    setIsNotesModalVisible(true);
  };

  const checkDataChanges = (record) => {
    const testingColumnData = safeJsonParse(record.testing_column);
    const hasChanges = Object.keys(testingColumnData).some(
      (key) => record[key] !== testingColumnData[key]
    );
    setIsDataChanged(hasChanges);
  };

  const handleAcceptChanges = async (currentRecord) => {
    try {
      const testingColumnData = safeJsonParse(currentRecord.testing_column);
      const payload = {
        ...testingColumnData,
        direct_update: true,
        admin_approval: "Accepted",
        
      };

      const response = await axios.post(
        `${BASE_URL}/api/document/save-orupdate`,
        payload
      );

      if (response.data.status) {
        toast.success("Changes accepted successfully");
        setIsModalVisible(false);
        window.location.reload();
      } else {
        toast.error("Failed to accept changes");
      }
    } catch (error) {
      console.error("Error accepting changes:", error);
      toast.error("Error accepting changes");
    }
  };

  const handleRejectChanges = async (currentRecord) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/document/save-orupdate`,
        {
          ...currentRecord,
          direct_update: true,
          admin_approval: "Rejected",
         
        }
      );
      if (response.data.status) {
        toast.success("Changes rejected successfully");
        setIsModalVisible(false);
        window.location.reload();
      } else {
        toast.error("Failed to reject changes");
      }
    } catch (error) {
      console.error("Error rejecting changes:", error);
      toast.error("Error rejecting changes");
    }
  };

  const getFieldData = (record) => {
    const testingColumns = ["testing_column"];
    let oldData = safeJsonParse(record.testing_column);
    const fieldNames = Object.keys(record).filter(
      (key) => !testingColumns.includes(key)
    );

    return fieldNames.map((key) => ({
      key,
      oldValue: record[key] !== undefined ? record[key] : "-",
      newValue: oldData[key] !== undefined ? oldData[key] : record[key],
    }));
  };

  const handleReasonSelect = (value) => {
    setSelectedReason(value);
  };

  const handleApprovalStatusSelect = (value) => {

    setSelectedApprovalStatus(value);
  };

  const safeJsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString || "{}");
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  const columns = [
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      key: "company_name",
      render: (text) => (
        <span className="group-hover:text-white border-none ">{text}</span>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      
      key: "id",
      render: (text) => <span className="group-hover:text-white ">{text}</span>,
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <span className="group-hover:text-white">{text}</span>,
    },
    {
      title: <div className="ml-4">{"CHANGES"}</div>,
      dataIndex: "reported_by_admin",
      key: "reported_by_admin",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleSeeChanges(record)}
          className="ant-btn-link "
        >
          See the Changes
        </Button>
      ),
    },
    {
      title: <div className="ml-4">{"REPORTING COMMENTS"}</div>,
      dataIndex: "reporting_comments",
      key: "reporting_comments",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleSeeNotes(record)}
          className="ant-btn-link"
        >
          Comments of Reporting
        </Button>
      ),
    },
    {
      title: "REASON FOR COMMENTS",
      dataIndex: "reason_for_reporting",
      key: "reason_for_reporting",
      render: (text) => <span className="group-hover:text-white">{text}</span>,
    },
    {
      title: "USER DATA UPDATED STATUS",
      dataIndex: "admin_approval",
      key: "admin_approval",
      render: (_, record) => {
        if (!record)
          return (
            <span className="group-hover:text-white">No changes found</span>
          );

        const testingColumnData = safeJsonParse(record.testing_column);
        const adminApproval =
          testingColumnData.admin_approval === "Pending"
            ? testingColumnData.admin_approval
            : record.admin_approval || "No changes found";

        return <span className="group-hover:text-white">{adminApproval}</span>;
      },
    },
  ];

  return (
    <>
<style>
  {`
    .reports-table .ant-table-thead > tr > th {
      
      background-color: white !important;
      border-bottom: none !important; /* Remove the bottom border in the column title section */
      color: #808080 !important; /* Make the title text gray */
      font-weight: normal !important;
      font-size: 13px;
      // font-weight:200px;
    }
      .reports-table .ant-table-row > td {
      font-size:14px;
      font-weight: 400;
      border: 2px solid black !important; /* Remove the separator between columns */
  }
    .reports-table .ant-table-row:nth-child(odd) > td {
    
      background-color: white !important; /* White background for odd rows */
    }
    
    .reports-table .ant-table-row:nth-child(even) > td {
      background-color: #F9FAFB !important; /* Light gray background for even rows */
    }
    
    .reports-table .ant-table-row > td {
      border: none !important; /* Remove the separator between columns */
    }
    
    .reports-table .ant-table-row:hover > td {
    
      background-color: #FF6B00 !important; /* Orange background on hover */
    }
    
    /* Style for links/buttons on hover */
    .reports-table .ant-table-row:hover .ant-btn-link {
      // border-bottom:1px solid white;
      
      color: white !important;
      text-decoration: underline !important;
    }
    
    /* Change text color to white on row hover */
    .reports-table .ant-table-row:hover .ant-table-cell span,
    .reports-table .ant-table-row:hover .ant-table-cell Button {
      color: white !important;
    }
  `}
</style>
      <BasePageContainer breadcrumb={breadcrumb}>
        <Title className="px-6 pt-3" level={4}>Filtered Reports</Title>
        <div style={{ display: "flex", gap: "20px", marginBottom: "20px",marginLeft:"20px" }}>
          <Select
            style={{ width: 300 }}
            placeholder="Select a reason"
            onChange={handleReasonSelect}
            value={selectedReason}
          >
            <Option value="all">All Reasons</Option>
            {options.map((option) => (
              <Option key={option.id} value={option.question}>
                {option.question}
              </Option>
            ))}
          </Select>

          <Select
            style={{ width: 300 }}
            placeholder="Select approval status"
            onChange={handleApprovalStatusSelect}
            value={selectedApprovalStatus}
          >
            <Option value="all">All Statuses</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Accepted">Accepted</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>
        </div>

        <Table
          columns={columns}
          dataSource={
            filteredDocuments.length > 0 ? filteredDocuments : documents
          }
          rowKey="id"
          loading={loading}
          scroll={{ x: "max-content" }}
          pagination={false}
          className="reports-table"
        
        />

        <div class="flex items-center justify-center my-5 space-x-4">
          <button
            className="px-4 py-2 bg-[#171733] text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <div className=" px-4 py-2 rounded-md ">
            <span className="font-medium">
              Page <span className="text-black">{currentPage}</span> of{" "}
              <span class="text-black">{totalPages}</span>
            </span>
          </div>
          <button
            className="px-4 py-2 w-20 bg-[#171733] text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>

        <Modal
          visible={isModalVisible}
          title="Review Changes"
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button
              key="accept"
              type="primary"
              onClick={() => handleAcceptChanges(currentRecord)}
              className="bg-black text-white"
              disabled={!isDataChanged}
            >
              Accept Changes
            </Button>,
            <Button
              key="reject"
              onClick={() => handleRejectChanges(currentRecord)}
              className="bg-black text-white"
              disabled={!isDataChanged}
            >
              Reject Changes
            </Button>,
          ]}
          className="max-w-4xl max-h-[80vh] overflow-auto"
        >
          {currentRecord && (
            <div className="p-4 max-h-[60vh] overflow-y-auto">
              <div className="flex flex-col">
                <div className="flex font-bold bg-gray-200 border-b border-gray-300">
                  <div className="flex-1 p-2 border-r border-gray-300 truncate">
                    Field Name
                  </div>
                  <div className="flex-1 p-2 border-r border-gray-300 truncate">
                    Original Data
                  </div>
                  <div className="flex-1 p-2 truncate">Reported Data</div>
                </div>
                {getFieldData(currentRecord).map(
                  ({ key, oldValue, newValue }) => (
                    <div
                      className={`flex border-b border-gray-300 ${
                        oldValue !== newValue ? "bg-yellow-100" : ""
                      }`}
                      key={key}
                    >
                      <div className="flex-1 p-2 border-r border-gray-300 truncate">
                        {key}
                      </div>
                      <div className="flex-1 p-2 border-r border-gray-300 truncate">
                        {oldValue}
                      </div>
                      <div className="flex-1 p-2 truncate">{newValue}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </Modal>

        <Modal
          visible={isNotesModalVisible}
          title="Reporting Comments"
          onCancel={() => setIsNotesModalVisible(false)}
          footer={[
            <Button
              key="close"
              onClick={() => setIsNotesModalVisible(false)}
              className="bg-black text-white"
            >
              Close
            </Button>,
          ]}
          className="max-w-4xl max-h-[80vh] overflow-auto"
        >
          <div className="p-4 max-h-[60vh] overflow-y-auto">
            <div className="flex flex-col">
              <div className="font-bold bg-gray-200 p-2 border-b border-gray-300">
                Reporting Comments
              </div>
              <div className="p-2 border-b border-gray-300">{currentNote}</div>
            </div>
          </div>
        </Modal>
      </BasePageContainer>
    </>
  );
}
