import React, { useState } from "react";
import { Button, Input, Upload, Typography, message } from "antd";
import { AiFillCheckCircle } from "react-icons/ai";
import { InboxOutlined } from "@ant-design/icons";
import { Loader2 } from "lucide-react";
import { supabase } from "../../config/supabase.js";
import { BASE_URL } from "../../redux/constants/constants.js";

const { Dragger } = Upload;
const { Title, Paragraph } = Typography;

export default function CtEyeExtraction() {
  const [sustainabilityFile, setSustainabilityFile] = useState({});
  const [financialFile, setFinancialFile] = useState({});
  const [sustainabilityFileList, setSustainabilityFileList] = useState([]);
  const [financialFileList, setFinancialFileList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [year, setYear] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resetForm = () => {
    setSustainabilityFile({});
    setFinancialFile({});
    setSustainabilityFileList([]);
    setFinancialFileList([]);
    setCompanyName("");
    setYear("");
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const handleError = (error) => {
    console.error("Error in CT Eye Extraction:", error);
  };

  const fetchLegalEntitySuggestions = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }
    try {
      const { data, error } = await supabase
        .from("lei_companies")
        .select("entityLegalName")
        .ilike("entityLegalName", `%${searchTerm}%`)
        .limit(5);

      if (error) throw error;

      setSuggestions(data.map((item) => item.entityLegalName));
      setShowSuggestions(data.length > 0);
    } catch (error) {
      handleError(new Error("Failed to fetch company suggestions"));
    }
  };

  const handleCompanyNameChange = (e) => {
    const newValue = e.target.value;
    setCompanyName(newValue);
    fetchLegalEntitySuggestions(newValue);
  };

  const handleSuggestionSelect = (suggestion) => {
    setCompanyName(suggestion);
    setShowSuggestions(false);
  };

  const validateInputs = () => {
    if (!companyName.trim()) {
      message.error("Please enter a company name");
      return false;
    }
    if (!year.trim()) {
      message.error("Please enter a reporting year");
      return false;
    }
    if(!financialFile || sustainabilityFile){
      message.error("Please upload a financial and/or sustainability report");
    }
   
    if (isNaN(year) || year.length !== 4) {
      message.error("Please enter a valid 4-digit year");
      return false;
    }
    return true;
  };

  const handleFileChange = (info, type) => {
    const selectedFile = info.file.originFileObj;

    if (selectedFile.type !== "application/pdf") {
      message.error("Only PDF files are allowed");
      return;
    }

    if (type === "sustainability") {
      setSustainabilityFile(selectedFile);
      setSustainabilityFileList(info.fileList);
    } else if (type === "financial") {
      setFinancialFile(selectedFile);
      setFinancialFileList(info.fileList);
    }
  };

  const handleStartExtraction = async () => {
    if (!validateInputs()) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("companyName", companyName);
    formData.append("year", year);
    formData.append("sustainabilityFile", sustainabilityFile);
    formData.append("financialFile", financialFile);

    try {
      const response = await fetch(
        `${BASE_URL}/api/document/cteye-extraction`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Extraction request failed");
      }

      message.success({
        content:
          data.message ||
          "Extraction request added. View database in 30 minutes.",
        duration: 5,
      });

      resetForm();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 text-[#000000] font-normal">
      <div>
        <div className="flex items-center ">
          <Title level={3} className="text-left font-bold mb-0">
            Extract Sustainability Data
          </Title>
          <span className="flex items-center ml-2">
            <AiFillCheckCircle className="text-green-500 mr-1" />
            System is Available
          </span>
        </div>

        <Paragraph className="text-left text-[15px] w-5/6 mb-6 mt-2">
          To create a company record in the database, provide the legal entity
          name, upload the sustainability or financial report, and reporting year.
        </Paragraph>

        <div className="space-y-4 ">
          {/* Company Name Input */}
          <div>
            <label className="block text-left text-[16px] font-bold mb-2">
              Legal entity name:
            </label>
            <div className="relative w-3/6">
              <Input
                placeholder="Enter the name of the company"
                value={companyName}
                onChange={handleCompanyNameChange}
                className="w-full py-2 px-3 border border-gray-300 rounded-md bg-[#F8F8F8] focus:border-orange-500"
              />
              {showSuggestions && (
                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                  {suggestions.length > 0 ? (
                    <>
                      <div className="px-4 py-2 font-bold text-gray-500 border-b">
                        Did you mean?
                      </div>
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleSuggestionSelect(suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="px-4 py-2 text-gray-500">
                      No suggestions available
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Year Input */}
          <div>
            <label className="block text-left text-[16px] font-bold mb-2">
              Reporting year:
            </label>
            <Input
              placeholder="Enter the reporting year (e.g., 2023)"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              maxLength={4}
              className="w-3/6 py-2 px-3 border border-gray-300 rounded-md bg-[#F8F8F8] focus:border-orange-500"
            />
          </div>

          {/* Sustainability Report Upload */}
          <div className="w-4/6 h-28 pt-12">
            <label className="block text-left text-[16px] font-bold mb-2">
              Upload Sustainability report:
            </label>
            <Dragger
              accept=".pdf"
              fileList={sustainabilityFileList}
              onChange={(info) => handleFileChange(info, "sustainability")}
              beforeUpload={(file) => file.type === "application/pdf"}
              className="bg-[#F8F8F8] border-gray-300 hover:border-orange-500"
            />
          </div>

          {/* Financial Report Upload */}
          <div className="w-4/6 h-28 pt-12">
            <label className="block text-left text-[16px] font-bold mb-2">
              Upload Financial report:
            </label>
            <Dragger
              accept=".pdf"
              fileList={financialFileList}
              onChange={(info) => handleFileChange(info, "financial")}
              beforeUpload={(file) => file.type === "application/pdf"}
              className="bg-[#F8F8F8] border-gray-300 hover:border-orange-500"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4 pt-10">
            <Button
              onClick={resetForm}
              className="w-[194px] h-[45px] rounded-md bg-[#171733] text-white font-bold hover:bg-[#2a2a4a] transition-colors"
              disabled={isLoading}
            >
              Reset
            </Button>
            <Button
              onClick={handleStartExtraction}
              disabled={isLoading}
              className={`w-[194px] h-[45px] rounded-md bg-[#FF6B00] text-white font-bold flex items-center justify-center
                ${
                  isLoading
                    ? "opacity-75 cursor-not-allowed"
                    : "hover:bg-[#ff8533] transition-colors"
                }`}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </>
              ) : (
                "Start extraction"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
