//Create Customer
export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAIL = "CREATE_CUSTOMER_FAIL";

//Get Customers
export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

//Create Sub Customer
export const CREATE_SUB_CUSTOMER_REQUEST = "CREATE_SUB_CUSTOMER_REQUEST";
export const CREATE_SUB_CUSTOMER_SUCCESS = "CREATE_SUB_CUSTOMER_SUCCESS";
export const CREATE_SUB_CUSTOMER_FAIL = "CREATE_SUB_CUSTOMER_FAIL";

//Get Sub Customers
export const GET_SUB_CUSTOMERS_REQUEST = "GET_SUB_CUSTOMERS_REQUEST";
export const GET_SUB_CUSTOMERS_SUCCESS = "GET_SUB_CUSTOMERS_SUCCESS";
export const GET_SUB_CUSTOMERS_FAIL = "GET_SUB_CUSTOMERS_FAIL";

//Remove Sub Customer
export const REMOVE_SUB_CUSTOMER_REQUEST = "REMOVE_SUB_CUSTOMER_REQUEST";
export const REMOVE_SUB_CUSTOMER_SUCCESS = "REMOVE_SUB_CUSTOMER_SUCCESS";
export const REMOVE_SUB_CUSTOMER_FAIL = "REMOVE_SUB_CUSTOMER_FAIL";

//Get Customer Info
export const GET_CUSTOMER_INFO_REQUEST = "GET_CUSTOMER_INFO_REQUEST";
export const GET_CUSTOMER_INFO_SUCCESS = "GET_CUSTOMER_INFO_SUCCESS";
export const GET_CUSTOMER_INFO_FAIL = "GET_CUSTOMER_INFO_FAIL";

//Update Customer Info
export const UPDATE_CUSTOMER_INFO_REQUEST = "UPDATE_CUSTOMER_INFO_REQUEST";
export const UPDATE_CUSTOMER_INFO_SUCCESS = "UPDATE_CUSTOMER_INFO_SUCCESS";
export const UPDATE_CUSTOMER_INFO_FAIL = "UPDATE_CUSTOMER_INFO_FAIL";


//Add WhiteList Companies
export const ADD_WHITE_LIST_COMPANIES_REQUEST =
  "ADD_WHITE_LIST_COMPANIES_REQUEST";
export const ADD_WHITE_LIST_COMPANIES_SUCCESS =
  "ADD_WHITE_LIST_COMPANIES_SUCCESS";
export const ADD_WHITE_LIST_COMPANIES_FAIL = "ADD_WHITE_LIST_COMPANIES_FAIL";

//Get WhiteList Companies
export const GET_WHITE_LIST_COMPANIES_REQUEST =
  "GET_WHITE_LIST_COMPANIES_REQUEST";
export const GET_WHITE_LIST_COMPANIES_SUCCESS =
  "GET_WHITE_LIST_COMPANIES_SUCCESS";
export const GET_WHITE_LIST_COMPANIES_FAIL = "GET_WHITE_LIST_COMPANIES_FAIL";

//Remove WhiteList Company
export const REMOVE_WHITE_LIST_COMPANY_REQUEST =
  "REMOVE_WHITE_LIST_COMPANY_REQUEST";
export const REMOVE_WHITE_LIST_COMPANY_SUCCESS =
  "REMOVE_WHITE_LIST_COMPANY_SUCCESS";
export const REMOVE_WHITE_LIST_COMPANY_FAIL = "REMOVE_WHITE_LIST_COMPANY_FAIL";

//Update API Limit
export const UPDATE_API_LIMIT_REQUEST = "UPDATE_API_LIMIT_REQUEST";
export const UPDATE_API_LIMIT_SUCCESS = "UPDATE_API_LIMIT_SUCCESS";
export const UPDATE_API_LIMIT_FAIL = "UPDATE_API_LIMIT_FAIL";

//Generate New Password
export const GENERATE_NEW_PASSWORD_REQUEST = "GENERATE_NEW_PASSWORD_REQUEST";
export const GENERATE_NEW_PASSWORD_SUCCESS = "GENERATE_NEW_PASSWORD_SUCCESS";
export const GENERATE_NEW_PASSWORD_FAIL = "GENERATE_NEW_PASSWORD_FAIL";

//Delete Account
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";
export const DELETE_ACCOUNT_RESET = "DELETE_ACCOUNT_RESET";